export default [


    {
        id: 21,
        name: '我的信息',
        metaName: 'information',
        level: 2,
        icon: 'haolv-iconfont haolv-iconbook',
        parentId: 2,
    },
    {
        id: 22,
        name: '修改密码',
        metaName: 'password',
        level: 2,
        icon: 'el-icon-edit-outline',
        parentId: 2,
    },
    {
        id: 23,
        name: '我的差标',
        metaName: 'business-travel-standard',
        level: 2,
        icon: 'haolv-iconfont haolv-iconbusiness-travel',
        parentId: 2,
    },
    {
        id: 24,
        name: '常用旅客',
        metaName: 'traveller',
        level: 2,
        icon: 'haolv-iconfont haolv-iconworker',
        parentId: 2,
    },



    {
        id: 41,
        name: '购买保险',
        metaName: 'insurance',
        level: 2,
        icon: 'haolv-iconsettlement',
        parentId: 4,
    },


    {
        id: 91,
        name: '我的订单',
        metaName: 'adminMyOrder',
        level: 2,
        icon: 'haolv-iconorder-icon',
        parentId: 9,
    },
    {
        id: 92,
        name: '我的出差',
        metaName: 'adminMyEvection',
        level: 2,
        icon: 'haolv-icongongwenbao',
        parentId: 9,
    },
    {
        id: 93,
        name: '我的预定',
        metaName: 'adminMyReserve',
        level: 2,
        icon: 'haolv-iconreserve',
        parentId: 9,
    },

    {
        id: 31,
        name: '我的申请',
        metaName: 'myApply',
        level: 2,
        icon: 'haolv-iconbianji',
        parentId: 3,
    },
    {
        id: 32,
        name: '待我审批',
        metaName: 'adminMyApprove',
        level: 2,
        icon: 'haolv-icongongwenbao',
        parentId: 3,
    },
    {
        id: 33,
        name: '代客下单',
        metaName: 'replaceOrder',
        level: 2,
        icon: 'haolv-icongongwenbao',
        parentId: 3,
    },


    {
        id: 81,
        name: '企业支付',
        metaName: 'enterprisesPay',
        level: 2,
        icon: 'haolv-icongongwenbao',
        parentId: 8,
    },
    {
        id: 82,
        name: '余额账户',
        metaName: 'adminFinanceBalance',
        level: 2,
        icon: 'haolv-iconjine',
        parentId: 8,
    },
    {   // 未申请的就显示授信申请
        id: 86,
        name: '授信申请',
        metaName: 'adminFinanceCreditApply',
        level: 2,
        icon: 'haolv-iconanquan',
        parentId: 8,
    },
    // {   // 申请通过之后显示授信账户页面
    //     id: 83,
    //     name: '授信账户',
    //     metaName: 'adminFinanceCredit',
    //     level: 2,
    //     icon: 'haolv-iconanquan',
    //     parentId: 8,
    // },
    {
        id: 84,
        name: '结算管理',
        metaName: 'settlementManagement',
        level: 2,
        icon: 'haolv-iconsettlement',
        parentId: 8,
    },
    {
        id: 85,
        name: '全部报表',
        metaName: 'allReport',
        level: 2,
        icon: 'haolv-iconall-report',
        parentId: 8,
    },



    {
        id: 71,
        name: '员工管理',
        metaName: 'staffManage',
        level: 2,
        icon: 'haolv-iconworker',
        parentId: 7
    },
    {
        id: 72,
        name: '公司管理',
        metaName: 'companyManage',
        level: 2,
        icon: 'haolv-iconbook',
        parentId: 7
    },
    {
        id: 73,
        name: '组织架构',
        metaName: 'structureMange',
        level: 2,
        icon: 'haolv-iconstructure',
        parentId: 7,
    },
    {
        id: 74,
        name: '外部客户',
        metaName: 'external-customer',
        level: 2,
        icon: 'haolv-iconuser-heart',
        routeName: 'admin-external-customer-list',
        parentId: 7
    },
    {
        id: 75,
        name: '成本中心',
        metaName: 'cost-center',
        level: 2,
        icon: 'haolv-iconcompany-money',
        parentId: 7,
    },
    {
        id: 76,
        name: '角色权限',
        metaName: 'role-permission',
        level: 2,
        icon: 'haolv-iconcompany-set',
        parentId: 7,
    },
    {
        id: 77,
        name: '审批流管理',
        metaName: 'approval-process',
        level: 2,
        icon: 'haolv-iconuser-set',
        parentId: 7,
    },
    {
        id: 78,
        name: '差旅管理',
        metaName: 'evectionManagement',
        level: 2,
        icon: 'haolv-iconbusiness-travel',
        parentId: 7,
    },
    {
        id: 79,
        name: '公告管理',
        metaName: 'notice',
        level: 2,
        icon: 'haolv-iconnotice1',
        parentId: 7,
    },
    {
        id: 70,
        name: '功能商店',
        metaName: 'functionMall',
        level: 2,
        icon: 'haolv-iconall-report',
        parentId: 7,
    },

    {
        id: 52,
        name: '机票报表',
        metaName: 'flightBillReport',
        level: 2,
        icon: 'haolv-iconflight-report',
        parentId: 5,
    },
    {
        id: 53,
        name: '酒店报表',
        metaName: 'hotelReport',
        level: 2,
        icon: 'haolv-iconhotel-report',
        parentId: 5,
    },
    {
        id: 54,
        name: '火车票对账报表',
        metaName: 'trainBillReport',
        level: 2,
        icon: 'haolv-icontrain-report',
        parentId: 5,
    },
    {
        id: 56,
        name: '差旅监控',
        metaName: 'reportForm',
        level: 2,
        icon: 'haolv-iconreport-form',
        parentId: 5,
    },
]
