import Q from 'q';
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info'
import consumer_user_logout
    from 'yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_user_logout'

export default {
    data() {
        return {
            userInfo: {}
        }
    },
    props: {
        navList: {
            type: Array,
            default: () => []
        }
    },
    components: {},
    created() {
    },
    mounted() {
        // get_user_info().then(res => {
        //     this.userInfo = res.datas
        // });
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        jumpNext(val) {
            // let routeName = ''
            // if (val.routeName) {
            //     routeName = val.routeName
            // } else if (val.children[0].routeName) {
            //     routeName = val.children[0].routeName
            // } else {
            //     routeName = val.children[0].children[0].routeName
            // }
            // this.$router.push({
            //     name: routeName
            // })
        },

        __btn_logout_click() {
            // const __this = this;
            // Q.when()
            //     .then(function () {
            //         return consumer_user_logout();
            //     })
            //     .then(function () {
            //         __this.$router.replace({
            //             name: `login`
            //         });
            //     })
            //     .catch(function (ex) {
            //         console.error(ex);
            //         console.trace();
            //         if (ex) {
            //             if (ex.stack) {
            //                 console.error(ex.stack);
            //             }
            //         }
            //         return Q.reject(ex);
            //     })
            //     ;

        },
    }
}
