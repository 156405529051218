
export default {
  data() {
    return {
      
    };
  },
  components: {
    
  },
  created() {},
  methods: {
    
  },
  mounted() {
    
  },
  activated() {
    
  },
  deactivated() {},
  destroyed() {},
  watch: {},
  computed: {},
  filters: {},
};
