export default [
    {
        id: 211,
        name: '我的信息',
        metaName: 'information',
        level: 3,
        parentId: 21,
        routeName: 'admin-my-information'
    },
    {
        id: 221,
        name: '修改密码',
        metaName: 'password',
        level: 3,
        parentId: 22,
        routeName: 'admin-change-password'
    },
    {
        id: 231,
        name: '我的差标',
        metaName: 'business-travel-standard',
        level: 3,
        parentId: 23,
        routeName: 'admin-business-travel-standard'
    },
    {
        id: 241,
        name: '常用旅客',
        metaName: 'traveller',
        level: 3,
        parentId: 24,
        routeName: 'admin-often-traveller'
    },


    {
        id: 411,
        name: '购买保险',
        metaName: 'insurance',
        level: 3,
        parentId: 41,
        routeName: 'admin-buy-insurance'
    },


    {
        id: 911,
        name: '机票',
        metaName: 'adminMyOrderFlight',
        level: 3,
        parentId: 91,
        routeName: 'admin-my-order-flight-orderList',
    },
    {
        id: 912,
        name: '酒店',
        metaName: 'adminMyOrderHotel',
        level: 3,
        parentId: 91,
        routeName: 'admin-hotel-order-list',
    },
    {
        id: 913,
        name: '火车票',
        metaName: 'adminMyOrderTrain',
        level: 3,
        parentId: 91,
        // routeName: 'admin-my-order-train',
    },
    {
        id: 921,
        name: '我的出差',
        metaName: 'adminMyEvection',
        level: 3,
        parentId: 92,
        routeName: 'admin-my-evection',
    },
    {
        id: 931,
        name: '我的预定',
        metaName: 'adminMyReserve',
        level: 3,
        parentId: 93,
        routeName: 'admin-my-reserve',
    },


    {
        id: 311,
        name: '我的申请',
        metaName: 'adminMyApply',
        level: 3,
        parentId: 31,
        routeName: 'admin-my-apply-manage-apply',
    },
    {
        id: 312,
        name: '我的参与',
        metaName: 'adminMyApplyParticipation',
        level: 3,
        parentId: 31,
        routeName: 'admin-my-apply-manage-participation',
    },
    {
        id: 321,
        name: '已审批',
        metaName: 'adminHaveApprove',
        level: 3,
        parentId: 32,
        routeName: 'admin-my-apply-manage-have-approve',
    },
    {
        id: 322,
        name: '未审批',
        metaName: 'adminNoApprove',
        level: 3,
        parentId: 32,
        routeName: 'admin-my-apply-manage-no-approve',
    },
    {
        id: 331,
        name: '代客下单',
        metaName: 'replaceOrder',
        level: 3,
        parentId: 33,
        routeName: 'admin-replace-order'
    },


    {
        id: 811,
        name: '企业待付款',
        metaName: 'enterprisesNoPay',
        level: 3,
        parentId: 81,
        routeName: 'admin-finance-company-noPay',
    },
    {
        id: 812,
        name: '企业已付款',
        metaName: 'enterprisesPay',
        level: 3,
        parentId: 81,
        routeName: 'admin-finance-company-pay',
    },
    {
        id: 821,
        name: '余额账户',
        metaName: 'adminFinanceBalance',
        level: 3,
        parentId: 82,
        routeName: 'admin-finance-balance-list',
    },
    { // 未申请的就显示授信申请
        id: 861,
        name: '授信申请',
        metaName: 'adminFinanceCreditApply',
        level: 3,
        parentId: 86,
        routeName: 'admin-finance-credit-apply',
    },
    // {  // 申请通过之后显示授信账户页面
    //     id: 831,
    //     name: '授信账户',
    //     metaName: 'adminFinanceCredit',
    //     level: 3,
    //     parentId: 83,
    //     routeName: 'admin-finance-credit',
    // },
    {
        id: 832,
        name: '已出账单',
        metaName: 'adminFinanceCreditHaveBill',
        level: 3,
        parentId: 83,
        routeName: 'admin-finance-credit-have-bill',
    },
    {
        id: 833,
        name: '未出账单',
        metaName: 'adminFinanceCreditNoHaveBill',
        level: 3,
        parentId: 83,
        routeName: 'admin-finance-credit-noHave-bill',
    },
    {
        id: 841,
        name: '结算管理列表',
        metaName: 'settlementManagement',
        level: 3,
        parentId: 84,
        routeName: 'admin-settlement-management-list',
    },
    {
        id: 851,
        name: '全部报表',
        metaName: 'allReportDetail',
        level: 3,
        parentId: 85,
        routeName: 'admin-all-report',
    },
    {
        id: 852,
        name: '火车票报表',
        metaName: 'trainReport',
        level: 3,
        parentId: 85,
        routeName: 'admin-train-report-all',
    },
    {
        id: 853,
        name: '酒店报表',
        metaName: 'hotelReportAll',
        level: 3,
        parentId: 85,
        routeName: 'admin-hotel-report-all',
    },
    {
        id: 854,
        name: '机票报表',
        metaName: 'adminFlightAllReport',
        level: 3,
        parentId: 85,
        routeName: 'admin-flight-allReport',
    },
    {
        id: 855,
        name: '超标报表',
        metaName: 'excessReport',
        level: 3,
        parentId: 85,
        routeName: 'admin-excess-train-report',
    },



    {
        id: 711,
        name: '员工管理',
        metaName: 'staffList',
        level: 3,
        parentId: 71,
        routeName: 'admin-staff-list'
    },
    {
        id: 721,
        name: '企业资料',
        metaName: 'company',
        level: 3,
        routeName: 'admin-company-management',
        parentId: 72,
    },
    {
        id: 722,
        name: '发票管理',
        metaName: 'invoice',
        level: 3,
        routeName: 'admin-invoice-management',
        parentId: 72,
    },
    {
        id: 723,
        name: '公帐管理',
        metaName: 'corporate',
        routeName: 'admin-corporate-account',
        level: 3,
        parentId: 72,
    },
    {
        id: 724,
        name: '配送管理',
        metaName: 'distribution',
        routeName: 'admin-distribution-management',
        level: 3,
        parentId: 72,
    },
    {
        id: 731,
        name: '部门管理',
        metaName: 'structureList',
        level: 3,
        routeName: 'admin-structure',
        parentId: 73
    },
    {
        id: 732,
        name: '职位管理',
        metaName: 'positionList',
        level: 3,
        routeName: 'admin-position',
        parentId: 73
    },
    {
        id: 733,
        name: '职级管理',
        metaName: 'rankList',
        level: 3,
        routeName: 'admin-rank',
        parentId: 73
    },
    {
        id: 741,
        name: '外部客户管理',
        metaName: 'external-customer-list',
        level: 3,
        routeName: 'admin-external-customer-list',
        parentId: 74
    },
    {
        id: 751,
        name: '成本中心',
        metaName: 'cost-center-list',
        level: 3,
        routeName: 'admin-cost-center-list',
        parentId: 75,
    },
    {
        id: 761,
        name: '角色权限',
        metaName: 'role-permission-list',
        level: 3,
        routeName: 'admin-role-permission-list',
        parentId: 76,
    },
    {
        id: 771,
        name: '审批流管理',
        metaName: 'approval-process-management',
        level: 3,
        routeName: 'admin-approval-process-management',
        parentId: 77,
    },
    {
        id: 772,
        name: '审批角色设置',
        metaName: 'admin-approve-role-set',
        level: 3,
        routeName: 'admin-approve-role-set',
        parentId: 77,
    },
    {
        id: 773,
        name: '免审设置',
        metaName: 'admin-exempt-approve-set',
        level: 3,
        routeName: 'admin-exempt-approve-set',
        parentId: 77,
    },
    {
        id: 780,
        name: '出差政策',
        metaName: 'evectionPolicy',
        level: 3,
        parentId: 78,
        routeName: 'admin-evection-policy',
    },
    {
        id: 782,
        name: '退改设置',
        metaName: 'ticketSet',
        level: 3,
        parentId: 78,
        routeName: 'admin-ticket-set',
    },
    {
        id: 783,
        name: '酒店城市级别',
        metaName: 'hotelCityLevel',
        level: 3,
        parentId: 78,
        routeName: 'admin-hotel-city-level',
    },
    {
      id: 784,
      name: '机票差标',
      metaName: 'evectionFlightPolicy',
      level: 3,
      parentId: 78,
      routeName: 'admin-evection-flightpolicy',
    },
    {
        id: 786,
        name: '火车票差标',
        metaName: 'evectionTrainPolicy',
        level: 3,
        parentId: 78,
        routeName: 'admin-evection-train-policy',
    },
    {
        id: 781,
        name: '出差规则',
        metaName: 'evectionRule',
        level: 3,
        parentId: 78,
        routeName: 'admin-evection-rule',
    },
    {
        id: 787,
        name: '用车差标',
        metaName: 'evectionCar',
        level: 3,
        parentId: 78,
        routeName: 'admin-evection-car',
    },
    {
        id: 791,
        name: '公告管理',
        metaName: 'notice',
        level: 3,
        parentId: 79,
        routeName: 'admin-notice',
    },
    {
        id: 701,
        name: '功能商店',
        metaName: 'functionMall',
        level: 3,
        parentId: 70,
        routeName: 'admin-function-mall',
    },


    {
        id: 521,
        name: '机票',
        metaName: 'flightBillReport',
        level: 3,
        parentId: 52,
        routeName: 'admin-flight-bill-report'
    },
    {
        id: 531,
        name: '酒店',
        metaName: 'hotelReport',
        level: 3,
        parentId: 53,
        routeName: 'admin-hotel-report'
    },
    {
        id: 541,
        name: '火车票对账报表',
        metaName: 'trainBillReport',
        level: 3,
        parentId: 54,
        routeName: 'admin-train-bill-report'
    },
    {
        id: 561,
        name: '差旅监控',
        metaName: 'haolvMonitor',
        level: 3,
        parentId: 56,
        routeName: 'admin-haolv-monitor'
    },





]

