export default [
    {
        id: 2,
        name: '行程预定',
        metaName: 'travelOrder',
        level: 1,
        parentId: 0,
        routeName: 'admin-travel-book-home',
    },
    // {
    //     id: 4,
    //     name: '铨成保险',
    //     metaName: 'insurance',
    //     level: 1,
    //     parentId: 0,
    // },
    {
        id: 9,
        name: '我的订单',
        metaName: 'myOrder',
        level: 1,
        parentId: 0,
    },
    {
        id: 3,
        name: '办公',
        metaName: 'myApply',
        level: 1,
        parentId: 0,
    },
    {
        id: 8,
        name: '财务管理',
        metaName: 'finance',
        level: 1,
        parentId: 0,
    },
    {
        id: 7,
        name: '公司管理',
        metaName: 'company',
        level: 1,
        parentId: 0
    },
    {
        id: 5,
        name: '报表',
        metaName: 'report',
        level: 1,
        parentId: 0
    },

]
