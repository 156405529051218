export default [

    {
        id: 9131,
        name: '我的订单',
        metaName: 'adminMyOrderTrainList',
        level: 4,
        parentId: 913,
        routeName: 'admin-my-order-train',
    },
    {
        id: 9132,
        name: '退票单',
        metaName: 'adminMyOrderTrainRefundList',
        level: 4,
        parentId: 913,
        routeName: 'admin-my-order-train-refund-list',
    },
    {
        id: 9133,
        name: '改签单',
        metaName: 'adminMyOrderTrainChangeList',
        level: 4,
        parentId: 913,
        routeName: 'admin-my-order-train-change-list',
    },
    {
        id: 9111,
        name: '我的订单',
        metaName: 'adminMyOrderFlightOrderList',
        level: 4,
        parentId: 911,
        routeName: 'admin-my-order-flight-orderList',
    },
    {
        id: 9112,
        name: '改签单',
        metaName: 'adminMyOrderFlightChangeList',
        level: 4,
        parentId: 911,
        routeName: 'admin-my-order-flight-changeList',
    },
    {
        id: 9113,
        name: '退款单',
        metaName: 'adminMyOrderFlightRefundForm',
        level: 4,
        parentId: 911,
        routeName: 'admin-my-order-flight-refundForm',
    },
    {
        id: 9114,
        name: '退票单',
        metaName: 'adminMyOrderFlightRefundOrder',
        level: 4,
        parentId: 911,
        routeName: 'admin-my-order-flight-refundOrder',
    },


    {
        id: 9121,
        name: '我的订单',
        metaName: 'adminMyOrderHotelRefundOrder1',
        level: 4,
        parentId: 912,
        routeName: 'admin-hotel-order-list',
    },
    {
        id: 9122,
        name: '退订单',
        metaName: 'adminMyOrderHotelRefundOrder2',
        level: 4,
        parentId: 912,
        routeName: 'admin-hotel-unsubscribe-list',
    },
    



    {
        id: 8521,
        name: '全部',
        metaName: 'trainReportAll',
        level: 4,
        parentId: 852,
        routeName: 'admin-train-report-all',
    },
    {
        id: 8522,
        name: '部门分析',
        metaName: 'trainReportDepartment',
        level: 4,
        parentId: 852,
        routeName: 'admin-train-report-department',
    },


    

    {
        id: 8531,
        name: '全部',
        metaName: 'hotelReportAll',
        level: 4,
        parentId: 853,
        routeName: 'admin-hotel-report-all',
    },
    {
        id: 8532,
        name: '部门分析',
        metaName: 'hotelReportDepartment',
        level: 4,
        parentId: 853,
        routeName: 'admin-hotel-report-department',
    },
    {
        id: 8533,
        name: '节省与损失',
        metaName: 'hotelThriftyLoss',
        level: 4,
        parentId: 853,
        routeName: 'admin-hotel-thrifty-loss',
    },



    {
        id: 8541,
        name: '全部',
        metaName: 'adminFlightAllReport',
        level: 4,
        parentId: 854,
        routeName: 'admin-flight-allReport',
    },
    {
        id: 8542,
        name: '部门分析',
        metaName: 'adminFlightBranchReport',
        level: 4,
        parentId: 854,
        routeName: 'admin-flight-branchReport',
    },

    {
        id: 8551,
        name: '火车票超标',
        metaName: 'excessTrainReport',
        level: 4,
        parentId: 855,
        routeName: 'admin-excess-train-report',
    },
    {
        id: 8552,
        name: '酒店超标',
        metaName: 'excessHotelReport',
        level: 4,
        parentId: 855,
        routeName: 'admin-hotel-overproof-statement',
    },
    {
        id: 8553,
        name: '机票超标',
        metaName: 'excessFlightReport',
        level: 4,
        parentId: 855,
        routeName: 'admin-excess-flight-report',
    },
]